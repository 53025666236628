import React, {useEffect,useState} from 'react'
import jae from './isabell.json'
import {graphql} from 'gatsby'
import Frau from '../../components/frau'

const  Isabell =({data})=> {
  const [fertig, setFertig]=useState(false)
   const daten= jae.daten
   const titel = jae.titel
   const utitel = jae.utitel
   
   useEffect(()=>{
    if(daten && data && titel && utitel){
     
      return setFertig(true)
    
   }
  
  
  },[daten,data,titel,utitel])
   
  if(fertig){
     return(
       <Frau daten={daten} data={data} titel={titel} utitel={utitel} />
     )
  }
  return ""
}

export default Isabell

export const isabelleQuery=graphql`
query{
  site {
      siteMetadata {
        author{
          name
        }
        description
        keywords
        siteUrl
        title
      }
    }
    file(sourceInstanceName: {eq: "welten"}, relativePath: {eq: "13.jpg"}) {
      id
      childImageSharp {
        id
        fluid {
          base64
          tracedSVG
          srcWebp
          srcSetWebp
          originalImg
          originalName
          presentationWidth
          presentationHeight
        }
      }
    }
      
    }
       
   
       


`